import React from "react"
import Layout from "../Components/Layout"
import About from "../Components/About"

const OmOss = () => {
  return (
    <Layout>
      <About />
    </Layout>
  )
}

export default OmOss
