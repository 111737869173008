import React from "react"
import logo from "../assets/logo.png"
import "../styles/about.css"

const About = () => {
  return (
    <div className={"about"}>
      <div className={"about__hero"}>
        <span className={"about__hero__img"}>
          <img src={logo} alt="logo" />
        </span>
        <span className={"about__hero__text"}>
          <h3>PRESENTATION AV MERA ELVISION AB</h3>
          <p>
            Mera Elvision AB startades 2010 av ägarna Michael Berglund och Mats
            Månsson, som båda har tidigare erfarenheter av att driva företag.
            <br />
            <br />
            Företagets säte samt kontor är i Huddinge. Företagets huvudsyssla är
            att utföra elinstallationer inom bostäder, kontor och lättare
            industrier. Vi har erfarenheter från både mindre och större
            entreprenader / projekt såsom flerfamiljsbostäder och villaområden
            till enstaka småhus.
            <br />
            <br />
            Vi sitter på bred kunskap och innehar allmän elbehörighet. Vår
            affärsidé är att kunna leverera en säker elinstallation som kunder
            kan känna sig trygga med.
          </p>
        </span>
      </div>
      <p className={"about__disclaimer"}>
        Vi medverkar till att lösa eventuell tvist vid Allmänna
        reklamationsnämnden om en konsument väljer att påkalla sådan
        tvistlösning. Allmänna reklamationsnämnden prövar tvister mellan
        konsumenter och företag och fattar beslut om hur tvisten ska lösas.
        Beslutet är en rekommendation och inte tvingande. Webbadress och
        postadress är allmänna reklamationsnämnden Box 174, 101 23 Stockholm,
        www.arn.se
      </p>
    </div>
  )
}

export default About
